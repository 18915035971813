import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CityStateService extends GlobalService{

  constructor(private _http: HttpClient) {
    super(_http);
  }

  getEstados() {
    return this.get(null, 'states');
  }

  getCidades(uf: string) {
    return this.get({uf}, 'cities');
  }


}
